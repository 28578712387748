/*! _utils.scss | Nephos | CSS Ninja */

/* ==========================================================================
Utils and Miscellaneous styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Action Bar
1. Mobile Navbar
2. Tabs
3. Switch
4. Popovers
5. Helpers
6. Fade in Up animation
7. Translate Left animation
8. Fade In Left animation
9. Gelatine animation
10. Scale in animation
11. Scale out animation
12. Levitate animation
=============================================================================
***/

/* ==========================================================================
0. Action Bar
========================================================================== */

.action-bar {
  margin: 0;
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  background: $white;
  z-index: 2;
  position: relative;
  justify-content: flex-end;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.3s;
  @media screen and (min-width: $tablet-min) {
    height: 100px;
    justify-content: flex-start;
    padding: 15px;
  }
  @media screen and (min-width: $desktop) {
    justify-content: center;
    padding: 15px 20px;
  }
  @media screen and (max-width: $mobile-max) {
    position: fixed;
    top: 0;
    z-index: 99998;
    .has-focus & {
      position: absolute;
    }
  }
}

/* ==========================================================================
1. Mobile Navbar
========================================================================== */

//Mobile mode navbar
.mobile-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  transform: translateY(-100%);
  transition: all 0.3s;
  //Active State
  &.is-active {
    transform: translateY(0);
  }
  //Navbar item
  .navbar-item {
    //Icon modifier
    &.is-icon {
      svg {
        stroke-width: 1.6px;
        stroke: $primary;
      }
    }
    //Sidebar toggler
    &.is-sidebar-toggler {
      svg {
        stroke: $muted-grey !important;
      }
    }
    //Flex shortcut
    &.is-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        display: block;
        &.menu-badge {
          color: $secondary;
          width: 20px;
          height: 20px;
          border: 1px solid $secondary;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 90%;
          font-weight: 500;
        }
      }
    }
  }
  //Menu Hamburger
  .navbar-burger:hover {
    background-color: #fff;
  }
  .navbar-burger span {
    background-color: $primary;
  }
  //Navbar Responsive menu
  .navbar-menu {
    background: $sidebar;
    .navbar-item,
    .navbar-link {
      color: $white;
    }
    //Link
    .navbar-link {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid lighten($sidebar, 10%);
      img {
        height: 32px;
        width: 32px;
        max-height: 32px !important;
        border-radius: 50%;
      }
      svg {
        height: 20px;
        width: 20px;
        stroke-width: 1.2px;
        stroke: $white;
      }
      span {
        margin: 0 10px;
        &.is-heading {
          font-size: 11px;
          letter-spacing: 1px;
          text-transform: uppercase;
        }
      }
    }
    //Mobile Dropdown
    .navbar-dropdown .navbar-item {
      font-size: 90%;
      padding: 0.75rem 1.5rem !important;
      &.is-atcive,
      &:hover {
        background: lighten($sidebar, 10%);
      }
    }
  }
}

/* ==========================================================================
2. Tabs
========================================================================== */

.tabs-wrapper {
  .tabs {
    overflow: hidden;
  }
  //faded tabs
  &.animated-tabs {
    .navtab-content {
      animation-name: fadeInLeft;
      animation-duration: 0.5s;
    }
  }
  //translated tabs
  &.translated-tabs {
    .navtab-content {
      animation-name: translateLeft;
      animation-duration: 0.5s;
    }
  }
  //navtab content
  .navtab-content {
    display: none;
    padding: 15px;
    min-height: 190px;
  }
  //active state
  .navtab-content.is-active {
    display: block;
  }
}

/* ==========================================================================
3. Switch
========================================================================== */

.form-switch {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  i {
    position: relative;
    display: inline-block;
    margin-right: 0.5rem;
    width: 46px;
    height: 26px;
    background-color: #e6e6e6;
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 42px;
      height: 22px;
      background-color: $white;
      border-radius: 11px;
      transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
      transition: all 0.25s linear;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 22px;
      height: 22px;
      background-color: $white;
      border-radius: 11px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
      transform: translate3d(2px, 2px, 0);
      transition: all 0.2s ease-in-out;
    }
  }

  &:active {
    i::after {
      width: 28px;
      transform: translate3d(2px, 2px, 0);
    }

    input {
      &:checked + i::after {
        transform: translate3d(16px, 2px, 0);
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:checked + i {
      background-color: $primary;

      &::before {
        transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
      }

      &::after {
        transform: translate3d(22px, 2px, 0);
      }
    }
  }
}

/* ==========================================================================
4. Popovers
========================================================================== */

.webui-popover {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 10px 4px !important;
  background: $sidebar !important;
  color: $white !important;
  border: none !important;
  margin-top: -20px !important;
  &.top > .webui-arrow {
    border-top-color: transparent !important;
  }
  //Arrow
  .webui-arrow {
    border-top-color: transparent !important;
    &:after {
      border-top-color: $sidebar !important;
    }
  }
  //Title
  .webui-popover-title {
    background-color: $sidebar !important;
    border-bottom: none !important;
    font-weight: 500;
  }
  //content
  .webui-popover-content {
    font-size: 0.75rem !important;
    .popover-flex-block {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 5px 0;
      .staff-avatar {
        height: 38px;
        width: 38px;
        border-radius: 50%;
      }
      //Block
      .content-block {
        margin-left: 10px;
        label,
        span {
          display: block;
        }
        label {
          color: lighten($sidebar, 35%);
          text-transform: uppercase;
          font-size: 70%;
        }
      }
      .fa.fa-star,
      .fa.fa-star-half {
        color: #ffd700;
      }
      .icon-block {
        height: 38px;
        width: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          height: 18px;
          width: 18px;
          stroke: $white;
          stroke-width: 1.3px;
          &.is-medium {
            height: 26px;
            width: 26px;
          }
        }
      }
    }
  }
}

/* ==========================================================================
5. Helpers
========================================================================== */

.is-vhidden {
  visibility: hidden !important;
}

.is-hidden {
  display: none !important;
}

.is-disabled {
  pointer-events: none;
  opacity: 0.4;
  cursor: default !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.is-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-bold-1 {
  font-weight: 400 !important;
}

.ml-5 {
  margin-left: 5px !important;
}
.mr-5 {
  margin-right: 5px !important;
}

.light-text {
  color: $white !important;
}

/* ==========================================================================
6. Fade in Up animation
========================================================================== */

.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
}

//Keyframes
@-webkit-keyframes fadeInUp {
  from {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes fadeInUp {
  from {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
//Settings
.preFadeInUp {
  opacity: 0;
}

.fadeInUp {
  opacity: 0;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/* ==========================================================================
7. Translate Left animation
========================================================================== */

//Keyframes
@-webkit-keyframes translateLeft {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes translateLeft {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
//Settings
.translateLeft {
  -webkit-animation-name: translateLeft;
  animation-name: translateLeft;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-transition: all 500ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
  transition: all 500ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

/* ==========================================================================
8. Fade In Left animation
========================================================================== */

//Keyframes
@-webkit-keyframes fadeInLeft {
  from {
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes fadeInLeft {
  from {
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
//Settings
.preFadeInLeft {
  opacity: 0;
}

.fadeInLeft {
  opacity: 0;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

/* ==========================================================================
9. Gelatine animation
========================================================================== */

@-webkit-keyframes gelatine {
  from,
  to {
    -webkit-transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.9, 1.1);
  }
  50% {
    -webkit-transform: scale(1.1, 0.9);
  }
  75% {
    -webkit-transform: scale(0.95, 1.05);
  }
}
@keyframes gelatine {
  from,
  to {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}
//Settings
.gelatine {
  -webkit-animation: gelatine 0.6s;
  animation: gelatine 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/* ==========================================================================
10. Scale in animation
========================================================================== */

//Keyframes
@-webkit-keyframes scaleIn {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}
@keyframes scaleIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
//Settings
.scaleIn {
  /* ... */
  -webkit-animation: scaleIn 0.5s;
  animation: scaleIn 0.5s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* ==========================================================================
11. Scale out animation
========================================================================== */

//Keyframes
@-webkit-keyframes scaleOut {
  from {
    -webkit-transform: scale(1);
  }
  to {
    -webkit-transform: scale(0);
  }
}
@keyframes scaleOut {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}
//Settings
.scaleOut {
  -webkit-animation: scaleOut 0.6s;
  animation: scaleOut 0.6s;
}

/* ==========================================================================
12. Levitate animation
========================================================================== */

//Keyframes
@-webkit-keyframes levitate {
  from {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
  to {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes levitate {
  from {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
  to {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
//Settings
.levitate {
  -webkit-animation-name: levitate;
  animation-name: levitate;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
