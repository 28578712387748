/*! core.scss | Nephos | CSS Ninja */

/* ==========================================================================
Nephos core 
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. CSS Imports
1. Resets and base setup
=============================================================================
***/

h1 {
  font-size: 36px;
  line-height: 1.5;
  text-align: center;
  @media screen and (min-width: 960px) {
    font-size: 42px;
    line-height: 1.25;
  }
}

h2 {
  font-size: 21px;
  line-height: 1.25;
  @media screen and (min-width: 960px) {
    font-size: 24px;
    line-height: 1.25;
  }
}

h3 {
  font-size: 18px;
}

h4 {
  margin-bottom: 30px;
}

$mobile-max: 768px;
$tablet-min: 769px;
$tablet-max: 1087px;
$desktop: 1088px;

/* ==========================================================================
0. CSS Imports
========================================================================== */

@import 'partials/theme-cellsforlife';
@import 'partials/colors';
@import 'partials/sidebar';
@import 'partials/quickview';
@import 'partials/cards';
@import 'partials/layout';
@import 'partials/categories';
@import 'partials/account';
@import 'partials/cart';
@import 'partials/orders';
@import 'partials/product';
@import 'partials/checkout';
@import 'partials/auth';
@import 'partials/buttons';
@import 'partials/search';
@import 'partials/filters';
@import 'partials/checkboxes';
@import 'partials/forms';
@import 'partials/invoice';
@import 'partials/pageloader';
@import 'partials/elements';
@import 'partials/utils';
@import 'partials/responsive';

/* ==========================================================================
1. Resets and base setup
========================================================================== */

html {
  background-color: $accent;
}

body,
button,
input,
select,
textarea {
  font-family: $primary-font;
  color: $secondary;
}

a {
  color: $primary;
}

.is-primary {
  color: $primary;
}

.page-subtitle {
  color: $primary;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  .button {
    margin-left: 15px;
  }
}

.section-title {
  margin-bottom: 30px;
}

.main-content {
  background: $accent;
  @media screen and (max-width: $mobile-max) {
    margin-top: 109px;
  }
}

.light-bar {
  background-color: #989898;
  height: 1px;
  position: relative;
  overflow: visible;
  margin-bottom: 20px;
  @media screen and (min-width: $tablet-min) {
    margin-bottom: 30px;
  }
  &:before {
    content: '';
    display: block;
    background-color: $primary;
    width: 94px;
    height: 3px;
    position: absolute;
    top: -1px;
  }
}

.text-centered {
  text-align: center;
}

.pricing-summary {
  width: 100%;
  font-size: 18px;
}

.summary-title {
  &.heavy {
    font-weight: 700;
  }
}

.summary-price {
  text-align: right;
  font-weight: 700;
  &.green {
    color: $green;
  }
}

.fine-print {
  font-size: 12px;
}

.button {
  &.is-wide {
    padding-left: 2.75em;
    padding-right: 2.75em;
  }
  &.text-wrap {
    height: auto;
    flex-wrap: wrap;
    white-space: normal;
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }
}

.content-block {
  strong {
    color: $white;
  }
}

// Storage cards
.storage-card {
  color: $primary;
  transition: 0.3s all;
  outline: none;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: none;
  overflow: visible;
  cursor: pointer;

  .card-body {
    color: $primary;
    border: 2px solid transparent;
    transition: 0.3s all;
    flex: 1;
    justify-content: center;
    min-height: 100px;
    @media screen and (min-width: 769px) {
      min-height: 235px;
    }
  }
  &:hover,
  &.selected {
    border-color: $white;
    background-color: $primary;
    color: $white;
    .card-body {
      color: $white;
      border-color: $white;
    }
    .storage-card-footer {
      border-color: $white;
      background-color: $primary;
    }
  }

  &.disabled {
    cursor: not-allowed;
    &:hover {
      background-color: #ccc;
      .storage-card-footer {
        background-color: #ccc;
      }
    }
  }

  .storage-card-footer {
    background: #cecece;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transition: 0.3s all;
  }
  &.blank {
    background: transparent;
    box-shadow: none;
    @media screen and (max-width: $mobile-max) {
      margin-bottom: -45px;
    }
    .storage-card-footer {
      background: transparent;
      border: none;
    }
    .card-body {
      background-color: $white;
      border: 2px solid transparent;
      border-radius: 0.1875rem;
      transition: all 0.5s;
      box-shadow: 0px 5px 43px rgba(0, 0, 0, 0.18);
    }
    &:hover,
    &.selected {
      .card-body {
        border-color: $white;
        background-color: $primary;
        color: $white;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
      }
    }

    &.disabled {
      background: transparent;
      &:hover {
        .card-body {
          background-color: #ccc;
        }
        .storage-card-footer {
          background-color: transparent;
        }
      }
    }
  }
}

.credit-card-popup {
  padding: 20px;
  h4 {
    margin: 0;
    font-size: 24px;
  }
}

.modal-card-foot p {
  font-size: 14px;
}

.review-container {
  padding: 32px 32px 16px;
}

.review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.review-title {
  font-weight: bold;
  font-size: 18px;
}

.review-list {
  li {
    margin-bottom: 16px;
  }
}

.family-image {
  width: 100%;
}

.cord-container {
  padding: 10px 24px;
  ul {
    padding-left: 24px;
    list-style: disc;
  }
  p,
  li {
    margin-top: 0;
    line-height: 1.25;
  }
  strong {
    color: #777;
  }
}

.page-title {
  font-size: 27px;
  color: $title-grey;
  line-height: 1.25;
  text-align: center;
  @media screen and (min-width: 960px) {
    font-size: 36px;
  }
}

.button {
  &.primary-inverse {
    background-color: $white;
    border-color: $white;
    color: $primary;
    transition: 0.2s all;
  }
}

.question-container {
  background-color: $smoke-white;
  padding: 30px;
  margin-bottom: 16px;
}

.refer-friend-promo {
  min-height: auto;
  @media screen and (min-width: 1280px) {
    background: url('../images/referfriend.png') no-repeat;
    min-height: 400px;
    background-color: $white;
    background-size: auto 100%;
    background-position: bottom left;
    display: flex;
    align-items: center;
    .card-body {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.refer-friend-text {
  font-size: 21px;
  @media screen and (min-width: 1280px) {
    max-width: 45%;
  }
}

.primary-text {
  color: $primary;
}

.add-friend-button {
  display: block;
  transition: 0.2s all;
  padding: 15px;
  @media screen and (min-width: $tablet-min) {
    padding: 30px;
  }
}

.add-friend-title {
  font-size: 18px;
  @media screen and (min-width: $tablet-min) {
    font-size: 24px;
  }
}

.add-friend-subtitle {
  font-size: 14px;
  @media screen and (min-width: $tablet-min) {
    font-size: 18px;
  }
}

.add-friend-form {
  margin-bottom: 24px;
  &:first {
    .remove-friend-button {
      display: none;
    }
  }
}

.questionnaire {
  .card-title {
    display: block;
  }
  .steps-wrapper {
    margin: 0 auto;
    width: 100%;
  }
  .step-list {
    li {
      width: 20%;
      text-indent: -9999px;
    }
  }
}

.step-titles {
  margin: 0 auto;
  display: none;
  align-items: center;
  @media screen and (min-width: 1020px) {
    display: flex;
  }
}

.step-titles-mobile {
  display: flex;
  margin: 0 auto;
  width: 100%;
  align-items: center;
  @media screen and (min-width: 1020px) {
    display: none;
  }
}

.step-title {
  width: 20%;
  text-align: center;
  font-size: 14px;
}

.review-yes {
  color: green;
  text-align: right;
}

.review-no {
  color: red;
  text-align: right;
}

.header-logo {
  width: 164px;
  height: 40px;
  background-size: 100%;
  display: block;
  margin-bottom: 15px;
  background-position: center;
  @media screen and (min-width: $tablet-min) {
    margin-bottom: 0;
    width: 205px;
    height: 50px;
  }
  @media screen and (min-width: $desktop) {
    display: inline-block;
    transform: none;
    position: absolute;
    left: 20px;
    top: 25px;
  }
}

.repeat-title-container {
  display: flex;
  align-items: center;
  p {
    margin-right: 15px;
  }
}

.modal-card-title {
  width: 100%;
  line-height: 1.25;
}

.chosen-select {
  width: 100%;
  height: 40px;
  border: 1px solid #dbdbdb;
  color: $placeholder;
  padding-left: 5px;
  font-size: 16px;
  border-radius: 4px;
  -webkit-appearance: none;
  background: #fff url('../js/chosen/chosen-sprite@2x.png') no-repeat;
  background-position: right -78px top 0;
}

.align-switch {
  @media screen and (max-width: $desktop) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    .form-switch {
      margin-left: 15px;
    }
    label {
      display: block;
    }
  }
}

.stack-mobile {
  flex-direction: column;
  @media screen and (min-width: 769px) {
    flex-direction: row;
  }
}

.columns.is-full {
  width: 100%;
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
  .form-switch {
    margin-left: 15px;
  }
}

.justify-sb {
  justify-content: space-between;
}

.is-text-centered-desktop {
  @media screen and (min-width: $tablet-min) {
    text-align: center;
  }
}

.card-wrapper {
  @media screen and (max-width: $mobile-max) {
    transform: scale(0.7);
  }
}

.text-red {
  color: $red;
}

.text-green {
  color: $green;
}

.navbar-burger {
  position: absolute;
  right: 2px;
  top: 14px;
  &:hover {
    background: transparent;
  }
  @media screen and (min-width: $tablet-min) {
    position: relative;
    top: 0;
    right: 0;
  }
}

.category-menu {
  .flyout-item {
    text-align: right;
    justify-content: flex-end;
    padding: 5px 20px;
    min-height: auto;
    a {
      color: $primary;
      display: inline-block;
    }
  }
}

.flyout-item-underline {
  text-align: left;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #ededed;
  margin-bottom: 15px;

  span {
    width: 100%;
    text-align: left;
    color: $secondary;
    display: block;
  }
}

.text-primary {
  color: $primary;
}

.columns {
  &.flat-bottom {
    margin-bottom: 0;
  }
}

.valign-middle {
  vertical-align: middle;
}

.question-wrap {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  @media screen and (min-width: $tablet-min) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.form-question {
  margin-bottom: 15px;
  @media screen and (min-width: $tablet-min) {
    margin-bottom: 0;
    padding-right: 15px;
    max-width: 80%;
  }
}

.form-answers {
  display: flex;
  align-items: center;
  width: 100%;
  @media screen and (min-width: $tablet-min) {
    width: auto;
  }
  @media screen and (min-width: $tablet-max) {
    min-width: 30%;
    justify-content: flex-end;
  }
}

.form-answer {
  display: flex;
  align-items: center;
  margin-right: 10px;
  .form-switch {
    margin-left: 5px;
  }

  @media screen and (min-width: $tablet-min) {
    margin-right: 0;
    margin-left: 10px;
  }
}

.question-details {
  .question-wrap {
    padding: 15px 0;
    &:first-child {
      padding-top: 30px;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
}

.review-list-item {
  &:last-child {
    margin-bottom: 0;
  }
}

.review-item-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.review-question {
  padding-right: 15px;
}

.add-friend-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.add-friend-wrap {
  text-align: center;
  padding-right: 5px;
}

.add-friend-plus {
  @media screen and (max-width: $mobile-max) {
    text-align: right;
    img {
      width: 55px;
      height: auto;
    }
  }
}

.payment-method {
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

html {
  @media screen and (min-width: $tablet-min) {
    zoom: 86%;
  }
}

.navbar-menu {
  &.is-active {
    @media screen and (max-width: $mobile-max) {
      position: fixed;
      top: 105px;
      width: 100%;
      z-index: 99;
    }
  }
}

.modal-card-foot {
  justify-content: center;
}

.review-label {
  font-weight: 600;
}

.contract-title {
  text-align: left;
  margin-bottom: 20px;
  @media screen and (min-width: $tablet-min) {
    font-size: 36px;
  }
}

.contract-list {
  padding-left: 20px;
  li {
    margin-bottom: 10px;
  }
}

.lower-alpha {
  list-style-type: lower-alpha;
}

.lower-roman {
  list-style-type: lower-roman;
}

.o-vis {
  overflow: visible;
}

.datepicker-panel > ul > li.highlighted {
  color: $primary !important;
}

.datepicker-panel > ul > li.picked,
.datepicker-panel > ul > li.picked:hover {
  background-color: $primary;
  color: $white !important;
}

.modal-or {
  padding-top: 15px;
  padding-bottom: 15px;
}

// Step 9
.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  @media screen and (min-width: $tablet-min) {
    margin-top: 60px;
  }
}

.social-icon {
  max-width: 50px;
  margin-left: 15px;
  &:first-child {
    margin-left: 0;
  }
}

.congrats-header {
  display: flex;
  justify-content: center;
  align-items: center;
  .page-subtitle {
    margin: 0;
  }
}

.congrats-header-icon {
  max-width: 30px;
  max-height: 30px;
  height: auto;
  display: none;
  margin-right: 15px;
  @media screen and (min-width: $tablet-min) {
    display: inline-block;
  }
}

.congrats-video {
  iframe {
    max-width: 100%;
  }
}

.country-form {
  margin-bottom: 20px;
  .chosen-container {
    margin-bottom: 20px;
  }

  .input {
    margin-bottom: 20px;
  }
}

.chosen-container .chosen-results {
  max-height: 100px;
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .contract-modal.modal.is-active {
    display: block;
    padding-top: 60px;
    .modal-card-body {
      max-height: calc(100vh - 240px);
    }
  }

  .chosen-container.chosen-container-active {
    .chosen-drop {
      opacity: 0;
      overflow: hidden;
      display: none;
      .chosen-search-input {
        display: none;
      }
    }
  }

  .chosen-container.chosen-container-active.chosen-with-drop {
    .chosen-drop {
      opacity: 1;
      overflow: visible;
      display: block;
      .chosen-search-input {
        display: block;
      }
    }
  }

  .chosen-container-single .chosen-single div::after {
    display: block;
  }

  .is-credit-card {
    .payment-icon {
      margin-top: 20px;
    }
  }

  .is-bank {
    .payment-icon {
      margin-top: 30px;
    }
  }

  .social-icons {
    display: block;
  }

  .social-icon {
    display: inline-block;
  }
}

/* - Internet Explorer 10–11
   - Internet Explorer Mobile 10-11 */
:-ms-input-placeholder {
  color: $placeholder !important;
  font-weight: 400 !important;
}

.cord-button .button-title {
  max-width: 420px;
}

.button.go-btn {
  background: #0ecf00;
  border: none;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
