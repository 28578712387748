/*! _checkout.scss | Nephos | CSS Ninja */

/* ==========================================================================
Checkout pages styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Steps indicator
1. Checkout Header
2. Checkout Cards
3. Spacing
=============================================================================
***/

/* ==========================================================================
0. Steps indicator
========================================================================== */

//Variables
$number-of-steps: 4 !default;
$bullet-size: 30px;
$bullet-size-large: 3em;

//Wrapper
.steps-wrapper {
  width: 100%;
  @media screen and (min-width: $tablet-min) {
    width: 60%;
  }
  //List
  .step-list {
    position: relative;
    overflow: hidden;
    counter-reset: wizard;
    list-style-type: none;
    li {
      position: relative;
      float: left;
      width: 100% / $number-of-steps;
      text-align: center;
      text-indent: -9999px;
      @media screen and (max-width: $mobile-max) {
        &:last-child {
          &:before {
            .questionnaire & {
              content: '';
            }
          }
        }
      }
      &:before {
        counter-increment: wizard;
        content: counter(wizard);
        display: block;
        color: $white;
        background-color: $accent;
        border: 2px solid $white;
        text-align: center;
        width: $bullet-size;
        height: $bullet-size;
        line-height: $bullet-size * 0.9;
        border-radius: $bullet-size;
        position: relative;
        left: 50%;
        margin-left: $bullet-size * -0.5;
        z-index: 1;
        font-size: 0.8rem;
        font-weight: 500;
        @media screen and (max-width: $mobile-max) {
          .questionnaire & {
            content: 'Q';
          }
        }

        @media screen and (min-width: 960px) {
          width: $bullet-size-large;
          height: $bullet-size-large;
          line-height: $bullet-size-large * 0.9;
          border-radius: $bullet-size-large;
          margin-left: $bullet-size-large * -0.5;
        }
      }

      & + li:after {
        content: '';
        display: block;
        width: 100%;
        background-color: $primary;
        height: 1px;
        position: absolute;
        left: -50%;
        top: $bullet-size / 2;
        z-index: 0;
        @media screen and (min-width: 960px) {
          top: 1.2em;
        }
      }
    }
    //Active state
    .active {
      color: $white;
      text-indent: 0;
      &:before {
        background: $primary;
        color: $white;
        border-color: $white;
        font-weight: 500;
      }
    }
    //Link between steps
    li li + li {
      &:after {
        content: '';
        display: block;
        width: 100%;
        background-color: $primary;
        height: 1px;
        position: absolute;
        left: -50%;
        top: 1.2em;
        z-index: 0;
      }
    }
    //Active State
    .active:after {
      background-color: $primary;
    }
  }
}

/* ==========================================================================
1. Checkout Header
========================================================================== */

.checkout-title {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  h2 {
    font-size: 1.6rem;
    font-weight: 400 !important;
    text-transform: uppercase;
  }
  h3 {
    font-weight: 500;
    color: $primary;
  }
  &.is-centered {
    justify-content: center;
  }
  //Filigrane logo
  .brand-filigrane {
    position: absolute;
    top: -5px;
    left: -27px;
    height: 48px;
    opacity: 0.4;
    //Centered modifier
    &.is-centered {
      left: 0;
      right: 0;
      top: -10px;
      margin: 0 auto;
      height: 60px;
    }
  }
}

.checkout-wrapper {
  padding: 60px 0;
}

/* ==========================================================================
2. Checkout Cards
========================================================================== */

.flat-card {
  //Checkout Form modifier
  &.is-checkout-form {
    //Overflow modifier
    &.has-overflow {
      overflow: visible !important;
    }
    //Title
    .form-title h3 {
      font-size: 1.2rem;
      font-weight: 300 !important;
      text-transform: uppercase;
      padding-bottom: 20px;
    }
    .column {
      //Column modifiers
      &.is-light-padded {
        padding: 15px 40px !important;
      }
    }
    //Contact info form
    .customer-wrapper {
      padding: 80px 0;
      .logged-as {
        font-size: 90%;
        color: $muted-grey-bold;
        padding-top: 10px;
      }
      //Username
      .username {
        font-weight: 500;
      }
      //Switch
      .switch {
        padding-top: 10px;
        font-size: 90%;
        font-weight: 500;
        a {
          color: $muted-grey-bold;
          &:hover {
            color: $primary;
          }
        }
      }
      //Image
      img {
        height: 75px;
        width: 75px;
        border-radius: 50%;
        border: 4px solid $white;
        box-shadow: 0px 5px 43px rgba(0, 0, 0, 0.18) !important;
        &.logo {
          border-radius: 0;
          height: 75px;
          width: auto;
        }
      }
    }
    //Shipping info form
    .shipping-wrapper {
      padding: 80px 10px;
      //Small shipping card
      .mini-card {
        position: relative;
        background: $white;
        border-radius: 6px;
        border: 2px solid $placeholder;
        padding: 20px;
        margin: 10px;
        overflow: visible;
        cursor: pointer;
        transition: all 0.3s;
        svg {
          height: 48px;
          fill: $placeholder;
        }
        //Shipping method
        .shipping-method {
          text-transform: uppercase;
          font-size: 70%;
          color: $placeholder;
          display: block;
        }
        //Price
        .shipping-price {
          text-transform: uppercase;
          font-size: 70%;
          font-weight: 500;
          color: $primary;
          display: none;
        }
        //Hover state
        &:hover {
          border-color: $muted-grey;
          svg {
            fill: $muted-grey;
          }
          .shipping-method {
            color: $muted-grey;
          }
        }
        //Active State
        &.is-active {
          border-color: $primary;
          svg {
            fill: $primary;
          }
          .shipping-method {
            color: $primary;
            display: none;
          }
          .shipping-price {
            display: block;
          }
        }
      }
    }
    //Shipping Addresse form
    .shipping-address-form {
      .column {
        padding: 0 0.75rem !important;
      }
      .chosen-single {
        max-height: 36px;
        span {
          max-height: 36px;
          display: block;
          position: relative;
          top: -2.5px;
          left: -8px;
        }
      }
    }
    //Order verification wrapper
    .verification-wrapper {
      padding: 80px 0;
      //Total
      .total-price {
        svg {
          width: 26px;
          height: 26px;
          stroke: $muted-grey;
        }
        span {
          display: block;
          &:nth-child(2) {
            font-size: 0.85rem;
            font-weight: 600;
            text-transform: uppercase;
          }
          &:nth-child(3) {
            font-size: 2rem;
            font-weight: 600;
            &:before {
              content: '$';
              position: relative;
              top: -10px;
              font-size: 50%;
            }
          }
          &:nth-child(4) {
            font-weight: 600;
            color: $primary;
            margin: 5px 0;
            font-size: 0.85rem;
          }
        }
      }
    }
    //Column
    .items-column {
      max-height: 350px;
      overflow-y: auto;
      //Scrollbar
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.2);
      }
      //Item
      .order-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        border-top: 1px solid $fade-grey;
        .item-description,
        .item-quantity {
          span {
            display: block;
            &:first-child {
              font-weight: 600;
              text-transform: uppercase;
              font-size: 80%;
            }
            &:nth-child(2) {
              font-size: 80%;
              color: $muted-grey-bold;
            }
          }
        }
        //Description
        .item-description {
          width: 50%;
        }
        //Quantity
        .item-quantity {
          width: 15%;
        }
        //Price
        .item-price {
          width: 25%;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 90%;
          color: $primary;
        }
      }
    }

    //Logo
    .logo {
      height: 35px;
      width: auto;
      border: none;
      box-shadow: none;
      display: block;
      &.is-centered {
        margin: 0 auto;
      }
    }
    //Bank Account
    .bank-account {
      padding: 10px 0;
      display: block;
      font-size: 90%;
      color: $muted-grey-bold;
    }
    //form Control
    .control {
      margin: 10px 0;
      &.has-margin {
        margin-left: 5px;
        margin-right: 5px;
      }
      .input {
        box-shadow: none !important;
        color: $muted-grey-bold;
      }
    }
    //Labels
    .checkout-label {
      font-weight: 500;
      color: $dark-text;
      font-size: 90%;
      margin: 5px 0;
      display: block;
    }
  }
  //Payment methos cards
  &.payment-method {
    text-align: center;
    padding: 40px;
    border: 2px solid transparent;
    cursor: pointer;
    min-height: 250px;
    //Icon
    .payment-icon {
      svg {
        height: 60px;
        width: 60px;
        fill: $placeholder;
        transition: all 0.3s;
      }
    }
    //Text
    .payment-text {
      text-transform: uppercase;
      padding: 10px 0;
      color: $placeholder;
    }

    .payment-subtext {
      font-size: 12px;
      color: $placeholder;
    }
    //Hover State
    &:hover {
      //Paypal modifier
      &.is-paypal {
        border-color: $primary;
        svg {
          fill: $primary;
        }
        .payment-text {
          color: $primary;
        }
      }
      //Bank modifier
      &.is-bank {
        border-color: $primary;
        svg {
          fill: $primary;
        }
        .payment-text {
          color: $primary;
        }
      }
      //Credit Card modifier
      &.is-credit-card {
        border-color: $primary;
        svg {
          fill: $primary;
        }
        .payment-text {
          color: $primary;
        }
      }
      //Cash modifier
      &.is-cash {
        border-color: $green;
        svg {
          fill: $green;
        }
        .payment-text {
          color: $green;
        }
      }
    }
  }
  //Order complete card
  &.thanks-card {
    padding: 40px;
    margin: 10px 0;
    //Image
    img {
      display: block;
      height: 75px;
      margin: 0 auto 10px auto;
    }
    //Heading
    .card-heading {
      font-size: 1rem;
      font-weight: 500;
    }
    p {
      padding: 10px 40px;
      font-size: 0.9rem;
      color: $muted-grey-bold;
    }
    //Button
    .big-button {
      border: 2px solid $primary;
      color: $primary;
      font-weight: 500;
      &:hover {
        border-color: lighten($primary, 20%);
        color: lighten($primary, 20%);
      }
    }
  }
}

/* ==========================================================================
3. Spacing
========================================================================== */

#payment-methods {
  padding: 10px 0;
}

.button-wrapper {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

//State indicator
.active-indicator {
  visibility: hidden;
  position: absolute;
  top: -16px;
  right: -16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 2px solid lighten($primary, 30%);
  border-radius: 50%;
  background: $primary;
  svg {
    width: 14px;
    height: 14px;
    stroke: $white;
    stroke-width: 3px;
  }

  .is-active &,
  .selected & {
    visibility: visible;
  }
}

.grey-column {
  background: $light-grey;
}

.is-padded {
  padding: 15px !important;
  @media screen and (min-width: 960px) {
    padding: 40px !important;
  }
}

//Payment form wrapper
.payment-wrapper {
  padding: 120px 0;
  //Subtotal
  .subtotal {
    padding: 5px 0;
    .partial-value,
    .vat-value {
      &:before {
        content: '$';
        font-size: 60%;
        position: relative;
        top: -6px;
      }
    }
    .partial-value {
      font-weight: 500;
      color: $muted-grey-bold;
    }
    .vat-value {
      font-weight: 500;
      color: $primary;
    }
    .plus-operator,
    .equal-operator {
      font-weight: 600;
    }
  }
  //Total
  .total {
    span {
      display: block;
      font-size: 2.2rem;
      font-weight: 700;
      color: $dark-text;
      &:before {
        content: '$';
        font-size: 60%;
        position: relative;
        top: -10px;
      }
    }
  }
}
