/*! _layout.scss | Nephos | CSS Ninja */

/* ==========================================================================
Basic layout settings
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Body
1. Shop Wrapper
=============================================================================
***/

/* ==========================================================================
0. Body
========================================================================== */

body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #ededed;
  position: relative;
}

/* ==========================================================================
1. Shop Wrapper
========================================================================== */

.shop-wrapper {
  height: 100%;
  min-height: 100vh;
  width: calc(100% - 80px);
  margin-left: 80px;
  position: relative;
  background: #f0f0f0;
  transition: all 0.3s;
  //Background image modifier
  &.has-background-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  //Mobile mode modifier
  &.is-mobile-mode {
    width: 100% !important;
    margin-left: 0 !important;
  }
  //Full screen slider
  .slider-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    max-height: 100vh;
    //slider wrapper
    .fullscreen-slick {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      min-height: 100vh;
      width: 100%;
      outline: none !important;
      &:focus,
      &:active {
        outline: none !important;
      }
      img {
        min-height: 100vh;
      }
    }
    //slide
    .full-slide {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      min-height: 100vh;
      outline: none !important;
      &:focus,
      &:active {
        outline: none !important;
      }
    }
    //slider dots
    .slick-dots {
      bottom: 20px;
      max-width: 200px;
      right: 0;
      li {
        width: 30px;
        height: 30px;
        button::before {
          font-size: 12px;
        }
        &.slick-active button::before {
          color: $primary;
        }
      }
    }
  }
  //Homepage Static caption
  .hero-caption {
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    padding: 120px 0;
    text-align: center;
    z-index: 1;
    h1 {
      font-size: 8rem;
      font-weight: 300 !important;
    }
    h2 {
      font-size: 1.6rem;
      margin-top: -44px;

      font-weight: 300 !important;
    }
    .button {
      margin-top: 20px;
      font-size: 0.8rem !important;
    }
  }
  //Filigrane title
  .background-caption {
    position: absolute;
    //top: -48px;
    right: 0;
    left: 0;
    margin: 0 auto;
    padding: 50px 0;
    text-align: center;
    z-index: 1;
    h1 {
      font-size: 14rem;
      font-weight: 900 !important;

      opacity: 0.04;
    }
  }
}

.vertical-space {
  margin-bottom: 30px;
  &--sm {
    margin-bottom: 15px;
  }
}
