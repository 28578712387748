/*! _theme-olr.scss | OLR | Codivated */

/* ==========================================================================
OLR theme variables 
========================================================================== */

$primary: #5e96d8; // #5EBBE1 in design file
$secondary: #707070;
$accent: #f0f0f0;

// Fonts
$primary-font: 'Work Sans', sans-serif;

//Base shadow
$base-shadow: rgba(0, 0, 0, 0.12);

//Primary box shadow
$primary-shadow-from: rgba(249, 92, 100, 0.42);
$primary-shadow-to: rgba(249, 92, 100, 0.2);
$primary-box-shadow: 0 14px 26px -12px $primary-shadow-from,
  0 4px 23px 0px $base-shadow, 0 8px 10px -5px $primary-shadow-to !important;

//Secondary box shadow
$secondary-shadow-from: rgba(50, 91, 255, 0.42);
$secondary-shadow-to: rgba(50, 91, 255, 0.2);
$secondary-box-shadow: 0 14px 26px -12px $secondary-shadow-from,
  0 4px 23px 0px $base-shadow, 0 8px 10px -5px $secondary-shadow-to !important;

//Accent box shadow
$accent-shadow-from: rgba(220, 45, 255, 0.42);
$accent-shadow-to: rgba(220, 45, 255, 0.2);
$accent-box-shadow: 0 14px 26px -12px $accent-shadow-from,
  0 4px 23px 0px $base-shadow, 0 8px 10px -5px $accent-shadow-to !important;

// Steps indicator
$number-of-steps: 8;

.header-logo {
  background: url('../images/logo/cellsforlife.png') no-repeat;
}
