/*! _forms.scss | Nephos | CSS Ninja */

/* ==========================================================================
Form controls styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Textareas
1. Form Controls
2. Inputs
=============================================================================
***/

/* ==========================================================================
0. Textareas
========================================================================== */

textarea.textarea {
  color: $muted-grey;
  box-shadow: none;
  overflow: hidden;
  transition: all 0.8s;
  //focus colors
  &:focus {
    border: 1px solid $fade-grey;
    box-shadow: none !important;
  }
  &.is-primary-focus:focus {
    border-color: $primary;
    ~ span.icon i {
      color: $primary;
    }
  }
  &.is-secondary-focus:focus {
    border-color: $secondary;
    ~ span.icon i {
      color: $secondary;
    }
  }
  &.is-accent-focus:focus {
    border-color: $accent;
    ~ span.icon i {
      color: $accent;
    }
  }
  &.is-grey-focus:focus {
    border-color: $title-grey;
    ~ span.icon i {
      color: $title-grey;
    }
  }
  &.is-default-focus:focus {
    border-color: $muted-grey;
    ~ span.icon i {
      color: $muted-grey;
    }
  }
  //growing textarea
  &.is-grow {
    min-height: 40px !important;
    height: 40px;
    resize: none;
    transition: all 0.5s;
    &:focus {
      height: 130px !important;
      border-color: $fade-grey;
    }
  }
  //embeded button inside textarea
  &.is-button {
    min-height: 60px !important;
    height: 60px !important;
    resize: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none !important;
    transition: all 0.6s;
    &:hover {
      border-color: $fade-grey;
    }
    &:focus {
      height: 130px !important;
      padding-bottom: 40px !important;
      border-color: $fade-grey;
    }
    + .textarea-button {
      display: flex;
      justify-content: flex-end;
      position: relative;
      border: 1px solid $fade-grey;
      border-top: none !important;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      background: $light-grey;
      button {
        margin: 10px 7px 7px 7px;
        font-size: 0.85rem;
        padding: 4px 10px 8px 10px;
        width: 92px;
        //border-radius: 50px;
      }
    }
  }
}

/* ==========================================================================
1. Form Controls
========================================================================== */

.control {
  &.control-medium {
    padding: 20px;
  }
  &.is-input-wrapper {
    margin: 10px 0;
    background: #f7f7f7;
  }
}

/* ==========================================================================
2. Inputs
========================================================================== */

.input {
  height: 40px;
  position: relative;
  transition: all 0.3s;
  &.is-minimal {
    box-shadow: none !important;
    &:focus {
      box-shadow: none !important;
      border: 1px solid $fade-grey;
    }
  }
  &.is-default {
    box-shadow: none;
    color: $muted-grey-bold;
    &:focus {
      border: 1px solid $fade-grey;
    }
  }
}

.chosen-container-single .chosen-single {
  height: 40px;
  background: #fff;
  border-color: #dbdbdb;
  box-shadow: none;
  span {
    line-height: 40px;
    font-size: 1rem;
    color: #828282;
  }
}

.chosen-container-active.chosen-with-drop {
  .chosen-drop {
    box-shadow: none;
    border-color: #dbdbdb;
  }
  .chosen-single {
    background: #fff;
    border-color: #dbdbdb;
    box-shadow: none;
    div b {
      background-position: -30px 5px;
    }
  }
}

.chosen-container-single .chosen-single div b {
  background-size: 78px 55.5px !important;
  background-position: -2px 5px;
}

.chosen-container .chosen-results li {
  line-height: 30px;
  font-weight: normal;
  font-size: 1rem;
  &.highlighted {
    background: #fff;
    color: $primary;
  }
}

.chosen-container-single .chosen-search {
  position: relative;
  z-index: 1010;
  margin: 0;
  padding: 3px 8px;
  white-space: nowrap;
}

.chosen-container-single .chosen-search input[type='text'] {
  margin: 1px 0;
  padding: 6px 20px 6px 6px;
  width: 100%;
  height: 40px;
  outline: 0;
  margin-bottom: 10px !important;
  border: 1px solid #ddd !important;
  background-color: #fbfbfb !important;
  display: block;
  position: relative;
  z-index: 9;
  font-size: 1rem;
  color: #828282;
  background-size: 78px 55.5px !important;
  background-position: 100% -30px !important;
}
